import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import { graphql } from "gatsby"
import "../styles/post.scss"
import "../styles/proyecto.scss"
import { MdKeyboardArrowDown } from "react-icons/md"
import Boton from "../components/boton"

export default function Post({ data, pageContext }) {
  const datos = data.strapiProyectosecurity
  const cms = `https://api.bullhost.es`
  const cuerpo = datos.cuerpo.replace(/src="\//g, `src="${cms}/`)
  const urlDestacada = `${cms}${datos.imagen_destacada.url}`

  return (
    <Layout>
      <Seo
        title={datos.titulo}
        imagen={urlDestacada}
        pathname={`/proyectos/${pageContext.slug}`}
        description={datos.extracto}
      />

      <section
        className="meta-post"
        style={{ backgroundImage: `url(${urlDestacada})` }}
      >
        <div className="overlay"></div>
        <div className="meta-texto">
          <h1>{datos.titulo}</h1>
        </div>
        <a
          aria-label="ir a contenido"
          className="flecha-bajar"
          href="#cuerpo-post"
        >
          <MdKeyboardArrowDown />
        </a>
      </section>
      <section className="datos-proyecto-fullwidth">
        <div className="datos-proyecto-container">
          <div className="col col-1">
            <div className="meta-dato">
              <small>Cliente</small>
              <p>{datos.cliente}</p>
            </div>
          </div>
          <div className="col col-2">
            <div className="meta-dato">
              <small>Año</small>
              <p>{datos.anyo}</p>
            </div>
          </div>
          <div className="col col-3">
            <small>Servicios prestados</small>
            <ul className="servicios">
              {datos.servicios.map((value, index) => {
                return <li key={index}>{value.nombre}</li>
              })}
            </ul>
          </div>
        </div>
      </section>
      <section className="cuerpo" id="cuerpo-post">
        <div className="texto" dangerouslySetInnerHTML={{ __html: cuerpo }} />
        <div className="enlace-volver">
          <Boton
            color="acento"
            url="/proyectos/"
            texto="Volver a proyectos"
            externo={false}
          />
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiProyectosecurity(slug: { eq: $slug }) {
      anyo
      cliente
      titulo
      cuerpo
      imagen_destacada {
        url
      }
      servicios {
        nombre
      }
    }
  }
`
